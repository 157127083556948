<template>
  <div class="lottery-list">
    <el-card>
      <!-- 顶部操作栏 -->
      <div class="toolbar">
        <el-button type="primary" @click="handleAdd">添加彩种</el-button>
        <el-input v-model="searchQuery" placeholder="搜索彩种名称" style="width: 200px;" clearable @clear="handleSearch"
          @keyup.enter.native="handleSearch">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
        </el-input>
      </div>

      <!-- PC端表格 -->
      <el-table v-if="!isMobile" :data="lotteryList" v-loading="loading" style="width: 100%; margin-top: 20px;">
        <el-table-column prop="id" label="ID" width="80" align="center" />
        <el-table-column label="图标" width="80">
          <template slot-scope="scope">
            <el-image style="width: 40px; height: 40px" :src="scope.row.icon" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="彩种名称" min-width="120" />
        <el-table-column prop="category" label="分类" width="100">
          <template slot-scope="scope">
            <el-tag :type="getCategoryType(scope.row.category)">
              {{ getCategoryName(scope.row.category) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="开奖说明" min-width="180" />
        <el-table-column label="排序" width="70" align="center">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.sort" :min="0" :max="99" size="mini" controls-position="right"
              style="width: 60px; text-align: center" :controls="false"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-switch :value="scope.row.status === 1"
              @change="(value) => handleStatusChange(scope.row, value)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="开奖来源" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.drawSource === 'api' ? 'warning' : 'success'" size="small">
              {{ scope.row.drawSource === 'api' ? '第三方接口' : '本地开奖' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="280">
          <template slot-scope="scope">
            <el-button type="text" style="color: #E6A23C;" @click="initializeLottery(scope.row.id)">初始化</el-button>
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" class="danger" @click="handleDelete(scope.row)">删除</el-button>
            <el-button type="text" style="color: #67C23A;" @click="goconfigPlays(scope.row)">配置玩法</el-button>
            <el-button type="text" style="color: #409EFF;" @click="handleApiConfig(scope.row)"
              v-if="scope.row.drawSource === 'api'">开奖API</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <el-card v-for="item in lotteryList" :key="item.id" class="mobile-item" shadow="hover">
          <div class="mobile-item-header">
            <div class="mobile-item-icon">
              <el-image style="width: 40px; height: 40px" :src="item.icon" fit="cover"></el-image>
            </div>
            <div class="mobile-item-info">
              <div class="mobile-item-title">{{ item.name }}</div>
              <div class="mobile-item-desc">{{ item.description }}</div>
            </div>
          </div>
          <div class="mobile-item-body">
            <div class="mobile-item-row">
              <span class="label">分类：</span>
              <el-tag :type="getCategoryType(item.category)">
                {{ getCategoryName(item.category) }}
              </el-tag>
            </div>
            <div class="mobile-item-row">
              <span class="label">排序：</span>
              <el-input-number v-model="item.sort" :min="0" style="width: 120px" size="small" controls-position="right"
                @change="(value) => handleSortChange(item, value)"></el-input-number>
            </div>
            <div class="mobile-item-row">
              <span class="label">状态：</span>
              <el-switch :value="item.status === 1" @change="(value) => handleStatusChange(item, value)"></el-switch>
            </div>
            <div class="mobile-item-row">
              <span class="label">开奖来源：</span>
              <div class="content">
                <el-tag :type="item.drawSource === 'api' ? 'warning' : 'success'" size="small">
                  {{ item.drawSource === 'api' ? '第三方接口' : '本地开奖' }}
                </el-tag>
              </div>
            </div>
          </div>
          <div class="mobile-item-footer">
            <div class="button-group">
              <el-button type="warning" size="mini" @click="initializeLottery(item.id)">初始化</el-button>
              <el-button type="primary" size="mini" @click="handleEdit(item)">编辑</el-button>
            </div>
            <div class="button-group">
              <el-button type="danger" size="mini" @click="handleDelete(item)">删除</el-button>
              <el-button type="success" size="mini" @click="goconfigPlays(item)">配置玩法</el-button>
            </div>
            <div class="button-group" v-if="item.drawSource === 'api'">
              <el-button type="info" size="mini" @click="handleApiConfig(item)">开奖API</el-button>
            </div>
          </div>
        </el-card>
      </div>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" />
      </div>
    </el-card>

    <!-- 添加/编辑彩种对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :width="isMobile ? '95%' : '500px'"
      :fullscreen="isMobile" custom-class="lottery-dialog" @close="resetForm">
      <el-form :model="lotteryForm" :rules="rules" ref="lotteryForm" :label-width="isMobile ? 'auto' : '100px'"
        :label-position="isMobile ? 'top' : 'right'">
        <el-form-item label="彩种名称" prop="name">
          <el-input v-model="lotteryForm.name" placeholder="请输入彩种名称" />
        </el-form-item>
        <el-form-item label="分类" prop="category">
          <el-select v-model="lotteryForm.category" placeholder="请选择分类" :loading="!categoryList.length">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
              <span>{{ item.name }}</span>
              <span v-if="item.status === 0" style="float: right; color: #909399; font-size: 12px">
                (已禁用)
              </span>
            </el-option>
          </el-select>
          <div class="form-item-tip" v-if="showCategoryStatusTip">
            <i class="el-icon-info" style="margin-right: 4px;"></i>
            <span>选中的分类已禁用，该彩种将不会在APP端显示</span>
          </div>
        </el-form-item>
        <el-form-item label="开奖说明" prop="description">
          <el-input v-model="lotteryForm.description" type="textarea" placeholder="请输入开奖说明" />
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <div class="icon-select-wrapper">
            <el-image v-if="lotteryForm.icon" style="width: 40px; height: 40px" :src="lotteryForm.icon" fit="cover">
              <div slot="error">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-button type="primary" plain @click="openIconDialog">选择图标</el-button>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="lotteryForm.sort" :min="0" :max="999" controls-position="right" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch :value="lotteryForm.status === 1" @change="(value) => lotteryForm.status = value ? 1 : 0" />
        </el-form-item>
        <el-form-item label="开奖来源" prop="drawSource">
          <el-select v-model="lotteryForm.drawSource" placeholder="请选择开奖来源">
            <el-option label="本地开奖" value="local" />
            <el-option label="第三方接口" value="api" />
          </el-select>
          <div class="form-item-tip" v-if="lotteryForm.drawSource === 'api'">
            <i class="el-icon-info" style="margin-right: 4px;"></i>
            <span>请在保存后点击"开奖API"钮进行详细配置</span>
          </div>
        </el-form-item>
        <el-form-item label="开奖时间" required>
          <el-select v-model="lotteryForm.drawRule.type" placeholder="请选择开奖时间类型">
            <el-option v-for="item in drawRuleTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>

          <!-- 每周固定时间 -->
          <template v-if="lotteryForm.drawRule.type === 'weekly'">
            <div class="draw-rule-item">
              <el-checkbox-group v-model="lotteryForm.drawRule.weekDays">
                <el-checkbox v-for="day in weekOptions" :key="day.value" :label="day.value">
                  {{ day.label }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </template>

          <!-- 每天固定时间或每周固定时间都需要时间点 -->
          <template v-if="['daily', 'weekly'].includes(lotteryForm.drawRule.type)">
            <div class="draw-rule-item">
              <div class="time-points-list">
                <div v-for="(time, index) in lotteryForm.drawRule.timePoints" :key="index" class="time-point-item">
                  <el-time-picker v-model="lotteryForm.drawRule.timePoints[index]" format="HH:mm" value-format="HH:mm"
                    placeholder="选择时间" />
                  <el-button type="text" icon="el-icon-delete" @click="removeTimePoint(index)"
                    v-if="lotteryForm.drawRule.timePoints.length > 1" />
                </div>
              </div>
              <el-button type="text" @click="addTimePoint">
                <i class="el-icon-plus"></i> 添加时间点
              </el-button>
            </div>
          </template>

          <!-- 固定时间间隔(高频彩) -->
          <template v-if="lotteryForm.drawRule.type === 'interval'">
            <div class="draw-rule-item">
              <el-input-number v-model="lotteryForm.drawRule.interval" :min="1" :max="60" label="间隔分钟" />
              <span class="interval-label">分钟</span>
            </div>
            <div class="draw-rule-item time-range">
              <el-time-picker v-model="lotteryForm.drawRule.startTime" format="HH:mm" value-format="HH:mm"
                placeholder="开始时间" size="small" style="width: 110px" />
              <span class="time-separator">至</span>
              <el-time-picker v-model="lotteryForm.drawRule.endTime" format="HH:mm" value-format="HH:mm"
                placeholder="结束时间" size="small" style="width: 110px" />
            </div>
          </template>
        </el-form-item>
        <el-form-item label="截止时间" prop="drawRule.betDeadlineMinutes">
          <el-input-number v-model="lotteryForm.drawRule.betDeadlineMinutes" :min="1" :max="60" :step="1"
            controls-position="right" />
          <span style="margin-left: 8px;">分钟</span>
          <div class="form-item-tip">
            <i class="el-icon-info" style="margin-right: 4px;"></i>
            <span>开奖前多少分钟停止投注</span>
          </div>
        </el-form-item>
        <el-form-item label="默认亏盈" prop="drawRule.profitRatio">
          <el-input-number v-model="lotteryForm.drawRule.profitRatio" :min="-100" :max="100" :step="1"
            controls-position="right" />
          <span style="margin-left: 8px;">%</span>
          <div class="form-item-tip">
            <i class="el-icon-info" style="margin-right: 4px;"></i>
            <span>负数表示亏损，正数表示盈利，如：-30表示亏损30%</span>
          </div>
        </el-form-item>
        <el-form-item label="期号规则">
          <el-input v-model="lotteryForm.drawRule.drawNoRule.format" placeholder="例如：yyyyMMddNNN">
            <template slot="append">
              <el-tooltip content="yyyy:年份 MM:月份 dd:日期 NNN:期号(N的数量代表位数)" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-input>
          <div class="form-item-tip">
            <i class="el-icon-info" style="margin-right: 4px;"></i>
            <span>N的数量决定期号位数，如：NNN表示3位数期号</span>
          </div>

          <el-select v-model="lotteryForm.drawRule.drawNoRule.reset" placeholder="请选择重置规则"
            style="margin-top: 10px; width: 100%;" @change="handleResetRuleChange">
            <el-option label="每天重置" value="daily" />
            <el-option label="每年重置" value="yearly" />
            <el-option label="永不重置" value="never" />
          </el-select>

          <el-time-picker v-if="lotteryForm.drawRule.drawNoRule.reset !== 'never'"
            v-model="lotteryForm.drawRule.drawNoRule.resetTime" format="HH:mm" value-format="HH:mm"
            placeholder="选择重置时间点" style="margin-top: 10px; width: 100%;" />

          <el-switch v-if="lotteryForm.drawRule.drawNoRule.reset === 'yearly'"
            v-model="lotteryForm.drawRule.drawNoRule.calendar" active-value="lunar" inactive-value="solar"
            active-text="农历" inactive-text="公历" style="margin-top: 10px; display: block;">
            <div class="form-item-tip" style="margin-top: 4px;">
              <i class="el-icon-info" style="margin-right: 4px;"></i>
              <span>选择农历后，年度重置将在春节时进行</span>
            </div>
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" :size="isMobile ? 'medium' : 'small'">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitting" :size="isMobile ? 'medium' : 'small'">确
          定</el-button>
      </div>
    </el-dialog>

    <!-- 图标选择对框 -->
    <el-dialog title="选择图标" :visible.sync="iconDialogVisible" :width="isMobile ? '95%' : '600px'" :fullscreen="isMobile"
      custom-class="icon-dialog" :show-close="true" :close-on-click-modal="false">
      <div class="icon-dialog-content">
        <div class="icon-list">
          <div v-for="icon in iconList" :key="icon.id" class="icon-item" :class="{ active: currentIcon === icon.url }"
            @click="handleIconSelect(icon)">
            <img :src="icon.url" :alt="icon.name">
            <span>{{ icon.name }}</span>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'LotteryList',
  data() {
    return {
      loading: false,
      searchQuery: '',
      lotteryList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      dialogTitle: '添加彩种',
      submitting: false,
      lotteryForm: {
        name: '',
        category: '',
        description: '',
        sort: 0,
        status: 1,
        drawRule: {
          type: 'daily', // daily:每天 weekly:每周 interval:固定间隔
          weekDays: [], // 选择周几 [1,3,5] 表示周一三五
          timePoints: ['21:30'], // 时间点列表
          interval: 5, // 间隔分钟数(适用于高频彩)
          startTime: '00:00', // 高频彩开始时间
          endTime: '23:59',   // 高频彩结束时间
          betDeadlineMinutes: 5, // 开奖前多少分钟停止投注
          profitRatio: 0, // 默认亏盈比例
          drawNoRule: {
            format: 'yyyyMMddNNN',
            length: 3,
            start: 1,
            reset: 'daily',
            resetTime: '00:00',
            calendar: 'solar'
          }
        },
        drawSource: 'local', // 默认本地开奖
        apiProvider: '', // API提供商
        apiLotteryCode: '', // API对应的彩种编码
      },
      rules: {
        name: [
          { required: true, message: '请输入彩种名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        category: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入开奖说明', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序号', trigger: 'blur' }
        ],
        'drawRule.type': [
          { required: true, message: '请选择开奖时间类型', trigger: 'change' }
        ],
        'drawRule.weekDays': [
          {
            validator: (rule, value, callback) => {
              if (this.lotteryForm.drawRule.type === 'weekly' && (!value || value.length === 0)) {
                callback(new Error('请选择开奖日期'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'drawRule.timePoints': [
          {
            validator: (rule, value, callback) => {
              if (['daily', 'weekly'].includes(this.lotteryForm.drawRule.type)) {
                if (!value || value.length === 0) {
                  callback(new Error('请至少添加一个开奖时间'));
                } else if (value.some(time => !time)) {
                  callback(new Error('开奖时间不能为空'));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'drawRule.interval': [
          {
            validator: (rule, value, callback) => {
              if (this.lotteryForm.drawRule.type === 'interval') {
                if (!value || value < 1) {
                  callback(new Error('请设置有效的时间间隔'));
                } else {
                  callback();
                }
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'drawRule.betDeadlineMinutes': [
          {
            validator: (rule, value, callback) => {
              if (!value || value < 1) {
                callback(new Error('请设置有效的截止时间'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        'drawRule.profitRatio': [
          {
            validator: (rule, value, callback) => {
              if (value === undefined || value === null) {
                callback(new Error('请设置亏盈比例'));
              } else if (value < -100 || value > 100) {
                callback(new Error('亏盈比例必须在-100到100之间'));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }
        ],
        drawSource: [
          { required: true, message: '请选择开奖来源', trigger: 'change' }
        ],
        apiProvider: [
          {
            required: true,
            message: '请选择API提供商',
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (this.lotteryForm.drawSource === 'api' && !value) {
                callback(new Error('请选择API提供商'));
              } else {
                callback();
              }
            }
          }
        ],
        apiLotteryCode: [
          {
            required: true,
            message: '请输入彩种编码',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (this.lotteryForm.drawSource === 'api' && !value) {
                callback(new Error('请输入彩种编码'));
              } else {
                callback();
              }
            }
          }
        ],
        'drawRule.drawNoRule.format': [
          { required: true, message: '请输入期号格式', trigger: 'blur' },
          {
            pattern: /^[yMdN]+$/,
            message: '格式只能包含y(年)、M(月)、d(日)、N(期号)',
            trigger: 'blur'
          }
        ],
        'drawRule.drawNoRule.length': [
          { required: true, message: '请设置期号长度', trigger: 'change' }
        ],
        'drawRule.drawNoRule.start': [
          { required: true, message: '请设置起始期号', trigger: 'change' }
        ],
        'drawRule.drawNoRule.reset': [
          { required: true, message: '请选择重置规则', trigger: 'change' }
        ],
        'drawRule.drawNoRule.resetTime': [
          {
            required: true,
            message: '请选择重置时间',
            trigger: 'change',
            validator: (rule, value, callback) => {
              if (this.lotteryForm.drawRule.drawNoRule.reset !== 'never' && !value) {
                callback(new Error('请选择重置时间'));
              } else {
                callback();
              }
            }
          }
        ]
      },
      iconDialogVisible: false,
      iconList: [],
      currentIcon: '',
      isMobile: false,
      categoryList: [],
      drawRuleTypes: [
        { label: '每天固定时间', value: 'daily' },
        { label: '每周固定时间', value: 'weekly' },
        { label: '固定时间间隔', value: 'interval' }
      ],
      weekOptions: [
        { label: '周一', value: 1 },
        { label: '周二', value: 2 },
        { label: '周三', value: 3 },
        { label: '周四', value: 4 },
        { label: '周五', value: 5 },
        { label: '周六', value: 6 },
        { label: '周日', value: 0 }
      ],
      apiProviders: [
        { label: '开彩网', value: 'kaiCai' },
        { label: '彩票控', value: 'caiPiaoKong' },
        // 可以添加更多API提供商
      ]
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchData()
    this.loadLocalIcons()
    this.fetchCategories()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    // 获取票列表据
    async fetchData() {
      this.loading = true
      try {
        const res = await this.$http.get('/api/admin/lottery/list', {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            query: this.searchQuery
          }
        })
        if (res.data.code === 200) {
          this.lotteryList = res.data.data.list.map(lottery => ({
            ...lottery,
            code: lottery.code || `lottery_${lottery.id}`
          }));
          this.total = res.data.data.total;
        }
      } catch (error) {
        console.error('获取彩票列表失败:', error)
        this.$message.error('获取数据失败')
      } finally {
        this.loading = false
      }
    },

    // 获取类别列表
    async fetchCategories() {
      try {
        const res = await this.$http.get('/api/admin/lottery/categories')
        if (res.data.code === 200) {
          this.categoryList = res.data.data
        }
      } catch (error) {
        console.error('获取类别列表失败:', error)
        this.$message.error('获取类别列表失败')
      }
    },

    // 获取分类名称
    getCategoryName(categoryId) {
      const category = this.categoryList.find(c => c.id === categoryId)
      return category ? category.name : '未知'
    },

    // 获取分类标签类型
    getCategoryType(categoryId) {
      const index = this.categoryList.findIndex(c => c.id === categoryId)
      const typeMap = {
        0: 'success',
        1: 'warning',
        2: 'info',
        3: 'danger'
      }
      return typeMap[index % 4] || ''
    },

    // 处理搜索
    handleSearch() {
      this.currentPage = 1
      this.fetchData()
    },

    // 处理添加
    handleAdd() {
      this.dialogTitle = '添加彩种'
      this.dialogVisible = true
      this.lotteryForm = {
        name: '',
        category: '',
        description: '',
        sort: 0,
        status: 1,
        drawRule: {
          type: 'daily', // daily:每天 weekly:每周 interval:固定间隔
          weekDays: [], // 选择周几 [1,3,5] 表示周一三五
          timePoints: ['21:30'], // 时间点列表
          interval: 5, // 间隔分钟数(适用于高频彩)
          startTime: '00:00', // 高频彩开始时间
          endTime: '23:59',   // 高频彩结束时间
          betDeadlineMinutes: 5, // 开奖前多少分钟停止投注
          profitRatio: 0, // 默认亏盈比例
          drawNoRule: {
            format: 'yyyyMMddNNN',
            length: 3,
            start: 1,
            reset: 'daily',
            resetTime: '00:00',
            calendar: 'solar'
          }
        },
        drawSource: 'local', // 默认本地开奖
        apiProvider: '', // API提供商
        apiLotteryCode: '', // API对应的彩种编码
      }
    },

    // 处理编辑
    handleEdit(row) {
      this.dialogTitle = '编辑彩种';
      this.dialogVisible = true;
      const drawRule = row.drawRule || {
        type: 'daily',
        timePoints: ['21:30'],
        weekDays: [],
        interval: 5,
        startTime: '00:00',
        endTime: '23:59',
        betDeadlineMinutes: 5,
        profitRatio: 0,
        drawNoRule: {
          format: 'yyyyMMddNNN',
          length: 3,
          start: 1,
          reset: 'daily',
          resetTime: '00:00',
          calendar: 'solar'
        }
      };

      // 确保 drawNoRule 存在
      if (!drawRule.drawNoRule) {
        drawRule.drawNoRule = {
          format: 'yyyyMMddNNN',
          length: 3,
          start: 1,
          reset: 'daily',
          resetTime: '00:00',
          calendar: 'solar'
        };
      }

      // 确保 betDeadlineMinutes 存在
      drawRule.betDeadlineMinutes = drawRule.betDeadlineMinutes || 5;
      // 确保 profitRatio 存在
      drawRule.profitRatio = drawRule.profitRatio ?? 0;

      this.lotteryForm = {
        ...row,
        drawRule
      };
    },

    // 处理删除
    handleDelete(row) {
      this.$confirm('确认删除该彩种吗？', '提示', this.getConfirmConfig('确认删除该彩种吗？'))
        .then(async () => {
          try {
            const res = await this.$http.delete(`/api/admin/lottery/${row.id}`);
            if (res.data.code === 200) {
              this.$message.success('删除成功');
              this.fetchData();
            } else {
              this.$message.error(res.data.message || '删除失败');
            }
          } catch (error) {
            console.error('删除彩种失败:', error);
            this.$message.error(error.response?.data?.message || '删除失败');
          }
        })
        .catch(() => {});
    },

    // 处理状态改变
    async handleStatusChange(row, value) {
      try {
        const res = await this.$http.put(`/api/admin/lottery/${row.id}/status`, {
          status: value ? 1 : 0
        })
        if (res.data.code === 200) {
          this.$message.success('状态更新成功')
          row.status = value ? 1 : 0
        }
      } catch (error) {
        console.error('更新状态失败:', error)
        this.$message.error('状态更新失败')
        row.status = value ? 0 : 1
      }
    },

    // 处理提交前的数据
    formatDrawRule(drawRule) {
      const formatted = { ...drawRule };

      // 根据类型清理无关数据
      if (drawRule.type === 'daily') {
        delete formatted.weekDays;
        delete formatted.interval;
        delete formatted.startTime;
        delete formatted.endTime;
      } else if (drawRule.type === 'weekly') {
        delete formatted.interval;
        delete formatted.startTime;
        delete formatted.endTime;
      } else if (drawRule.type === 'interval') {
        delete formatted.weekDays;
        delete formatted.timePoints;
      }

      // 对时间点进行排序
      if (formatted.timePoints) {
        formatted.timePoints.sort();
      }

      return formatted;
    },

    // 提交表单
    submitForm() {
      this.$refs.lotteryForm.validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const formData = { ...this.lotteryForm };
            formData.drawRule = this.formatDrawRule(formData.drawRule);
            
            const isEdit = !!formData.id;
            const url = isEdit 
              ? `/api/admin/lottery/${formData.id}`
              : '/api/admin/lottery';
            const method = isEdit ? 'put' : 'post';
            
            const res = await this.$http[method](url, formData);
            
            // 无论返回的是 0 还是 200 都认为是成功
            if (res.data.code === 200 || res.data.code === 0) {
              this.$message.success(`${isEdit ? '编辑' : '添加'}成功`);
              this.dialogVisible = false;  // 确保关闭对话框
              await this.fetchData();  // 确保刷新列表，使用 await 等待刷新完成
            } else {
              this.$message.error(res.data.message || `${isEdit ? '编辑' : '添加'}失败`);
            }
          } catch (error) {
            console.error(`${isEdit ? '编辑' : '添加'}彩种失败:`, error);
            this.$message.error(error.response?.data?.message || `${isEdit ? '编辑' : '添加'}失败`);
          } finally {
            this.submitting = false;
          }
        }
      });
    },

    // 重置表单
    resetForm() {
      this.$refs.lotteryForm?.resetFields()
      this.currentIcon = ''
    },

    // 处理分页小改变
    handleSizeChange(val) {
      this.pageSize = val
      this.fetchData()
    },

    // 处理页码改变
    handleCurrentChange(val) {
      this.currentPage = val
      this.fetchData()
    },

    // 加载本地图标
    loadLocalIcons() {
      try {
        const iconContext = require.context('../../../public/static/icon', false, /\.(png|jpe?g|gif|svg)$/i)

        this.iconList = iconContext.keys().map((key, index) => {
          const name = key.replace(/^\.\//, '').replace(/\.[^/.]+$/, '')
          const url = `/static/icon/${key.replace(/^\.\//, '')}`
          return {
            id: `icon_${index}`,
            name,
            url
          }
        })
      } catch (error) {
        console.error('加载图标失败:', error)
        this.$message.error('加载图标失败')
      }
    },

    // 打开图标选择对话
    openIconDialog() {
      this.iconDialogVisible = true
      this.currentIcon = this.lotteryForm.icon || ''
    },

    // 处理图标择
    handleIconSelect(icon) {
      this.currentIcon = icon.url
      this.lotteryForm.icon = icon.url
      this.iconDialogVisible = false
    },

    // 添加设备检测方法
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },

    // 处理排序变更
    async handleSortChange(row, value) {
      try {
        await this.$http.put(`/api/admin/lottery/${row.id}`, {
          sort: value
        });
      } catch (error) {
        console.error('更新排序失败:', error);
        this.fetchData();
        this.$message.error('更新排序失败');
      }
    },

    // 获取确认对话框配置
    getConfirmConfig(message, title = '提示') {
      return {
        title,
        message,
        customClass: 'mobile-confirm-dialog',
        center: this.isMobile,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: this.isMobile ? 'confirm-button' : '',
        cancelButtonClass: this.isMobile ? 'cancel-button' : '',
        type: 'warning'
      }
    },

    // 添加配置玩法方法
    goconfigPlays(lottery) {
      this.$router.push({
        name: 'LotteryPlaysConfig',
        params: {
          id: lottery.id,
          name: lottery.name,
          category: lottery.category
        }
      });
    },

    // 修改处理API配置的方法
    handleApiConfig(row) {
      this.$router.push({
        name: 'LotteryApiConfig',
        params: {
          id: row.id,
          name: row.name
        }
      });
    },

    addTimePoint() {
      this.lotteryForm.drawRule.timePoints.push('00:00')
    },

    removeTimePoint(index) {
      this.lotteryForm.drawRule.timePoints.splice(index, 1)
    },

    // 显示API编码帮助信息
    showApiCodeHelp() {
      const provider = this.apiProviders.find(p => p.value === this.lotteryForm.apiProvider);
      if (!provider) {
        this.$message.warning('请先选择API提供商');
        return;
      }

      // 可以根据不同提供商显示不同的帮助信息
      const helpContent = {
        kaiCai: '开彩网彩种编码说明：\n- 双色球：ssq\n- 大乐透：dlt\n...',
        caiPiaoKong: '彩票控彩种编码说明：\n- 双色球：2\n- 大乐透：1\n...'
      };

      this.$alert(helpContent[this.lotteryForm.apiProvider], '彩种编码说明', {
        confirmButtonText: '确定',
        customClass: 'api-help-dialog'
      });
    },

    // 处理重置规则变化
    handleResetRuleChange(value) {
      // 强制更新视图
      this.$nextTick(() => {
        // 如果切换到"永不重置"，清空重置时间
        if (value === 'never') {
          this.lotteryForm.drawRule.drawNoRule.resetTime = '';
        } else if (!this.lotteryForm.drawRule.drawNoRule.resetTime) {
          // 如果没有重置时间，设置默认值
          this.lotteryForm.drawRule.drawNoRule.resetTime = '00:00';
        }

        // 如果不是年度重置，重置日历类型为公历
        if (value !== 'yearly') {
          this.lotteryForm.drawRule.drawNoRule.calendar = 'solar';
        }
      });
    },

    // 初始化彩种
    async initializeLottery(id) {
      try {
        const res = await this.$http.post(`/api/admin/lottery/initialize/${id}`);
        if (res.data.code === 0) {
          this.$message.success('彩种初始化成功');
        } else {
          this.$message.warning(res.data.message);
        }
      } catch (error) {
        console.error('初始化彩种失败:', error);
        this.$message.error('初始化彩种失败');
      }
    }
  },
  computed: {
    showCategoryStatusTip() {
      if (!this.lotteryForm.category || !this.categoryList.length) return false;
      const selectedCategory = this.categoryList.find(c => c.id === this.lotteryForm.category);
      return selectedCategory && selectedCategory.status === 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-list {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .toolbar {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;

      .el-input {
        width: 100% !important;
      }
    }
  }

  .mobile-list {
    .mobile-item {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .mobile-item-header {
        display: flex;
        margin-bottom: 12px;

        .mobile-item-icon {
          margin-right: 12px;
        }

        .mobile-item-info {
          flex: 1;

          .mobile-item-title {
            font-size: 15px;
            font-weight: 500;
            color: #303133;
            margin-bottom: 4px;
          }

          .mobile-item-desc {
            font-size: 13px;
            color: #909399;
          }
        }
      }

      .mobile-item-body {
        margin-bottom: 12px;

        .mobile-item-row {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            width: 50px;
            font-size: 13px;
            color: #606266;
          }
        }
      }

      .mobile-item-footer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 12px;

        .button-group {
          display: flex;
          gap: 8px;

          .el-button {
            flex: 1;
            margin-left: 0;

            &.el-button--warning {
              background-color: #E6A23C;
              border-color: #E6A23C;
              color: #fff;
            }

            &.el-button--primary {
              background-color: #409EFF;
              border-color: #409EFF;
            }

            &.el-button--danger {
              background-color: #F56C6C;
              border-color: #F56C6C;
            }

            &.el-button--success {
              background-color: #67C23A;
              border-color: #67C23A;
            }

            &.el-button--info {
              background-color: #909399;
              border-color: #909399;
            }
          }
        }
      }
    }
  }

  .danger {
    color: #F56C6C;
  }
}

// 移动端弹窗样式
.lottery-dialog {
  @media screen and (max-width: 768px) {
    .el-dialog__body {
      padding: 12px !important;
    }

    .el-form-item {
      margin-bottom: 12px;
    }

    .el-form-item__label {
      padding-bottom: 4px;
      line-height: 1.2;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .el-button {
        flex: 1;
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    .mobile-item-header {
      .mobile-item-info {
        .mobile-item-title {
          color: #fff !important;
        }

        .mobile-item-desc {
          color: #909399 !important;
        }
      }
    }

    .mobile-item-body {
      .mobile-item-row {
        .label {
          color: #909399 !important;
        }
      }
    }
  }
}

// 修改图标选择器样式
.icon-dialog {
  .icon-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 12px;
    padding: 0 4px;

    .icon-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      padding: 8px;
      border: 1px solid #eee;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        border-color: #409EFF;
        background-color: #f5f7fa;
      }

      &.active {
        border-color: #409EFF;
        background-color: #ecf5ff;
      }

      img {
        width: 32px; // 固图标大
        height: 32px;
        object-fit: contain;
      }

      span {
        font-size: 12px;
        color: #666;
        text-align: center;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  // 移动端配
  @media screen and (max-width: 768px) {
    .icon-list {
      grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
      gap: 8px;

      .icon-item {
        padding: 6px;

        img {
          width: 28px; // 动端稍微小一点
          height: 28px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .icon-dialog {
    .icon-list {
      .icon-item {
        border-color: #4c4c4c;

        &:hover {
          background-color: #363636;
        }

        &.active {
          border-color: #409EFF;
          background-color: #363636;
        }

        span {
          color: #909399;
        }
      }
    }
  }
}

.form-item-tip {
  margin-top: 8px;
  font-size: 12px;
  color: #E6A23C;
  display: flex;
  align-items: center;
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .form-item-tip {
    color: #e6a23c;
  }
}

.icon-select-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.draw-rule-item {
  margin-top: 10px;

  &.time-range {
    display: flex;
    align-items: center;

    .time-separator {
      margin: 0 8px;
      color: #606266;
    }
  }

  .time-points-list {
    .time-point-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .el-button {
        margin-left: 8px;
      }
    }
  }

  .interval-label {
    margin-left: 8px;
  }
}

.api-config {
  padding: 10px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  margin-top: 10px;

  .el-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// 暗黑模式
@media (prefers-color-scheme: dark) {
  .api-config {
    border-color: #4c4c4c;
  }
}

// 移动端下拉框样式优化
:deep(.el-select-dropdown) {
  @media screen and (max-width: 768px) {
    position: fixed !important;
    left: 5% !important;
    right: 5% !important;
    width: 90% !important;
    margin-bottom: 44px !important;
    max-height: 50vh !important; // 设置最大高度为视口高度的50%

    .el-scrollbar {
      max-height: calc(50vh - 20px) !important;

      .el-select-dropdown__wrap {
        max-height: none !important;
      }

      .el-select-dropdown__list {
        padding: 4px 0;
      }
    }
  }
}

// 移动端下拉选项样式优化
:deep(.el-select-dropdown__item) {
  @media screen and (max-width: 768px) {
    height: 40px !important; // 增加选项高度
    line-height: 40px !important;
    font-size: 14px !important;
    padding: 0 12px !important;
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  :deep(.el-select-dropdown) {
    background-color: #1e1e1e !important;
    border-color: #363636 !important;

    .el-select-dropdown__item {
      color: #e6e6e6 !important;

      &.hover,
      &:hover {
        background-color: #363636 !important;
      }

      &.selected {
        color: #409EFF !important;
        background-color: #2b2b2b !important;
      }
    }
  }
}
</style>